import { Controller } from "stimulus";

export default class ActiveLinksController extends Controller {
  static targets = ["link"];

  get className() {
    return this.data.get("className") || "active";
  }

  connect() {
    const currentHref = window.location.href;

    for (const link of this.linkTargets) {
      const href = link.href;
      const exact = link.dataset.exact === "true";
      const active = exact ? currentHref === href : currentHref.startsWith(href);

      if (active) {
        link.classList.add(this.className);
      }
    }
  }

  disconnect() {
    for (const link of this.linkTargets) {
      link.classList.remove(this.className);
    }
  }
}
