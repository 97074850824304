import { Controller } from "stimulus";
import throttle from "underscore/modules/throttle";
import { on } from "../utils";

export default class ElementShiftController extends Controller {
  static targets = ["shifted"];

  connect() {
    if (this.hasShiftedTarget) {
      this.maxShiftPercent = Number(this.data.get("maxShiftPercent"));
      this.unbind = on(
        document,
        "mousemove",
        throttle((event) => this.onMouseMove(event), 100)
      );
    }
  }

  onMouseMove(event) {
    const { clientX, clientY } = event;
    const { left, top, width, height } = this.element.getBoundingClientRect();
    const isMouseOver =
      clientX >= left && clientX <= left + width && clientY >= top && clientY <= top + height;

    if (!isMouseOver) {
      return;
    }

    const ratioX = (clientX - left) / width - 0.5;
    const ratioY = (clientY - top) / height - 0.5;
    const shifted = this.shiftedTarget;
    shifted.style.setProperty("--img-x-shift", `${-1 * this.maxShiftPercent * ratioX}%`);
    shifted.style.setProperty("--img-y-shift", `${-1 * this.maxShiftPercent * ratioY}%`);
  }

  disconnect() {
    if (this.unbind) {
      this.unbind();
      this.unbind = null;
    }
  }
}
