import { Controller } from "stimulus";
import KeenSlider from "keen-slider";

const dotActiveClassName = "active";

export default class QuotesSliderController extends Controller {
  static targets = ["list", "dots", "slide"];

  connect() {
    this.slider = new KeenSlider(this.listTarget, {
      loop: true,
      created: (instance) => {
        const dots_wrapper = this.dotsTarget;
        const slides = Array.from(this.slideTargets);

        slides.forEach((_, index) => {
          const dot = document.createElement("button");
          dot.type = "button";
          dot.setAttribute("aria-label", `Citation ${index + 1}`);
          dot.classList.add("quotes_slider__dot");
          dots_wrapper.appendChild(dot);
          dot.addEventListener("click", () => instance.moveToSlide(index));
        });

        this.updateClasses(instance);
      },
      slideChanged: (instance) => {
        this.updateClasses(instance);
      }
    });
  }

  disconnect() {
    this.slider.destroy();
    this.slider = null;
    this.dotsTarget.innerHTML = "";
  }

  updateClasses(slider) {
    const slide = slider.details().relativeSlide;
    const dots = Array.from(this.dotsTarget.childNodes);

    dots.forEach((dot, index) => {
      index === slide
        ? dot.classList.add(dotActiveClassName)
        : dot.classList.remove(dotActiveClassName);
    });
  }
}
