import { Controller } from "stimulus";

export default class EmailLinkController extends Controller {
  connect() {
    this.element.insertBefore(this.getLink(), this.element.firstChild);
  }

  getLink() {
    const link = document.createElement("a");
    const email = `${this.data.get("who")}@${this.data.get("domain")}`;
    const href = `mailto:${email}`;
    link.href = href;
    link.textContent = email;
    return link;
  }
}
